import SetupProgressCompletion from "src/components/SelfSetup/SetupProgressCompletion";
import CardFormWithStripe from "src/components/SelfSetup/CardFormWithStripe";
import ChoosePlan from "src/components/SelfSetup/ChoosePlan";
import BasicForm from "src/components/SelfSetup/BasicForm";
import Logo from "src/components/Shared/Icons/Logo";
import WizardLayout from "src/layouts/WizardLayout";
import { useState } from "react";

const SelfSetup = () => {
  const formJson = {
    name: "",
    email: "",
    password: "",
    company_name: "",
    domain: "",
    interval: "",
    plan: null,
  };

  const [formData, setFormData] = useState(formJson);
  const [view, setView] = useState("basicForm");

  const onHandleChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const steps = ["Basic Info", "Choose Plan", "Payment Details", "Completion"];
  const stepIndex = {
    basicForm: 0,
    choosePlan: 1,
    cardForm: 2,
    setupProgress: 3,
  };

  return (
    <>
      <WizardLayout leftHeaderContent={<Logo />}>
        <div className="relative z-0 mx-auto my-4 flex w-full items-center justify-center">
          {steps.map((label, index) => (
            <div
              key={index}
              className="relative flex flex-1 items-center justify-center">
              <div className={`flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full font-bold text-white ${index <= stepIndex[view] ? "bg-blue-500" : "bg-gray-300"}`}>{index + 1}</div>
            </div>
          ))}
          <div className="absolute left-1/2 top-3.5 z-[-1] h-1 w-[calc(100%-120px)] -translate-x-1/2 overflow-hidden rounded-full bg-gray-100">
            <div
              className="absolute left-0 top-0 h-full bg-blue-500 transition-all duration-300"
              style={{ width: `${(stepIndex[view] / (steps.length - 1)) * 100}%` }}></div>
          </div>
        </div>

        <div className="flex justify-between text-sm text-gray-500">
          {steps.map((label, index) => (
            <div
              key={index}
              className={`flex-1 text-center ${index <= stepIndex[view] ? "text-black" : ""}`}>
              {label}
            </div>
          ))}
        </div>

        <div className="my-3">
          {view === "basicForm" && (
            <BasicForm
              formData={formData}
              onHandleChange={onHandleChange}
              setView={setView}
            />
          )}
          {view === "choosePlan" && (
            <ChoosePlan
              formData={formData}
              onHandleChange={onHandleChange}
              setView={setView}
            />
          )}
          {view === "cardForm" && (
            <CardFormWithStripe
              formData={formData}
              onHandleChange={onHandleChange}
              setView={setView}
            />
          )}
          {view === "setupProgress" && <SetupProgressCompletion formData={formData} />}
        </div>
      </WizardLayout>
    </>
  );
};

export default SelfSetup;
