import { classNames } from "src/helpers/classNames";

const H1 = ({ className = "", children, item = { defaultCss: "", onHoverCss: "", onClick: null } }) => {
  return (
    <h1 className={`font-extrabold flex items-center gap-x-2 text-2xl sm:text-3xl xl:text-4xl md:mb-6 text-gray-700 ${className}`}>
      {item.icon ? (
        <item.icon
          onClick={item.onClick}
          className={classNames("h-10 w-10", item.defaultCss, item.onHoverCss)}
        />
      ) : null}
      {children}
    </h1>
  );
};

const H2 = ({ className = "", margin = true, children }) => {
  return (
    <div className={classNames("-ml-4 flex", margin ? "md:mb-4" : "")}>
      <h2 className={`font-bold text-xl sm:text-2xl xl:text-3xl py-1 px-4 text-gray-900 ${className}`}>{children}</h2>
    </div>
  );
};

const H3 = ({ className = "", caption = "", children, wrapperClass, captionClassName = "" }) => {
  return (
    <div className={classNames("sm:my-2 mb-4 w-full flex flex-col sm:px-0", wrapperClass)}>
      <h3 className={`pb-.5 text-xl font-medium text-gray-900 ${className}`}>{children}</h3>
      {caption && <p className={`text-md text-gray-400 ${captionClassName}`}>{caption}</p>}
    </div>
  );
};

const H4 = ({ className = "", caption = "", margin = true, children }) => {
  return (
    <div className={classNames("-mt-[2px] text-sm text-gray-600", margin ? "ml-3 " : "")}>
      <h4 className={className}>{children}</h4>
      <p className="p-0 text-gray-400/80">{caption}</p>
    </div>
  );
};

const H5 = ({ className = "", caption = "", children }) => {
  return (
    <div className="ml-3 -mt-[2px] text-sm text-gray-600">
      <h5 className={className}>{children}</h5>
      <p className="p-0 text-gray-400/80">{caption}</p>
    </div>
  );
};

export { H1, H2, H3, H4, H5 };
