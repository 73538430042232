import React, { useState } from "react";
import { H3 } from "../Shared/Text/Headers";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Button from "../Shared/Buttons/Button";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardForm = ({ formData = null, onHandleChange = () => {}, setView = () => {} }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);

      let confirmError, paymentIntent;

      if (formData?.mode === "payment_intent") {
        const response = await stripe.confirmCardPayment(formData?.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: formData?.name,
            },
          },
        });
        confirmError = response.error;
        paymentIntent = response.paymentIntent;
      } else {
        const response = await stripe.confirmCardSetup(formData?.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: formData?.name,
            },
          },
        });
        confirmError = response.error;
        paymentIntent = response.setupIntent;
      }

      if (confirmError) {
        toast.error(confirmError.message);
        setIsSubmitting(false);
        return;
      }

      // Check if the payment succeeded
      if (paymentIntent.status !== "succeeded") {
        toast.error("Payment status unknown.");
        setIsSubmitting(false);
        return;
      }

      const { data } = await apiRequest("POST", "/self-setup", { body: { ...formData, plan: formData?.plan?._id, payment_method: paymentIntent?.payment_method, payment_id: paymentIntent?.id } });
      onHandleChange("domain", data?.data?.domain);
      setView("setupProgress");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-12">
      <H3
        caption="Start exploring data in five minutes all with a 14-day free trial."
        className="!font-semibold">
        Input your card details!
      </H3>
      <div className="space-y-6">
        <div className="relative">
          <CardElement />
        </div>
        <div className="relative space-y-4 pt-10">
          <Button
            version="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
            className="!w-full"
            onClick={onHandleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

// Wrapping CardForm in Elements provider
const CardFormWithStripe = ({ formData, onHandleChange, setView }) => (
  <Elements stripe={stripePromise}>
    <CardForm
      formData={formData}
      onHandleChange={onHandleChange}
      setView={setView}
    />
  </Elements>
);

export default CardFormWithStripe;
