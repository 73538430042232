import React, { useEffect, useRef, useState } from "react";
import { H3 } from "../Shared/Text/Headers";
import { classNames } from "src/helpers/classNames";
import Button from "../Shared/Buttons/Button";
import useFetch from "src/hooks/useFetch";
import { formatPrice } from "src/helpers/priceHelper";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import Toggle from "../Shared/Forms/Toggles/Toggle";

const DraggableContainer = ({ children, classes }) => {
  const [isDragging, setIsDragging] = useState(false);
  const scrollRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    startX.current = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX.current) * 1;
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
  };
  return (
    <div
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      className={classNames("scrollbar flex cursor-grab gap-0 overflow-auto pb-1 active:cursor-grabbing", classes)}
      style={{ scrollbarWidth: "none" }}>
      {children}
    </div>
  );
};

const PlanItem = ({ data, formData = null, onClick }) => {
  return (
    <div className="relative flex px-2 py-2">
      <button
        type="button"
        className={classNames("flex w-full min-w-[240px] rounded-xl border border-gray-200 bg-white px-4 py-4 text-left shadow-sm", formData?.plan?._id === data?._id ? "ring-2 ring-highlightColor ring-offset-2" : "")}
        onClick={onClick}>
        <div className="flex h-full w-full flex-col">
          <div className="mb-3 space-y-1">
            <div className="text-lg font-semibold text-highlightColor">{data.name}</div>
            <div className="text-base !leading-tight text-gray-600">{data.description}</div>
            {/* <div className="text-sm !leading-tight text-gray-400">{"features"}</div> */}
          </div>
          <div className="!mt-auto text-lg font-semibold text-highlightColor">
            {`${formatPrice(data?.pricing?.rates?.find((rate) => rate?.interval === formData?.interval)?.amount - data?.pricing?.rates?.find((rate) => rate?.interval === formData?.interval)?.discount_amount || 0)}`}
            <span className="text-sm font-medium text-gray-400">{` / ${formData?.interval}`}</span>
          </div>
        </div>
      </button>
    </div>
  );
};

const ChoosePlan = ({ formData = null, onHandleChange = () => {}, setView = () => {} }) => {
  const [planList, setPlanList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    response: { data: plans },
  } = useFetch(`/plans`);

  const getPlansByBillingCycle = (type) => {
    return plans?.filter((plan) => plan?.pricing?.rates?.find((rate) => rate?.interval === type));
  };

  const setPlanListAndBillingCycle = (type) => {
    if (plans?.length > 0 && type) {
      const getPlan = getPlansByBillingCycle(type);
      setPlanList(getPlan);
      onHandleChange("interval", type);
    }
  };

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { data } = await apiRequest("POST", `/choose-plan/validation`, { body: { ...formData, plan: formData?.plan?._id } });
      onHandleChange("clientSecret", data?.data?.clientSecret);
      onHandleChange("mode", data?.data?.mode);
      setView("cardForm");
    } catch (error) {
      setIsSubmitting(false);
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (plans?.length > 0) {
      const uniqueIntervals = [...new Set(plans.flatMap((plan) => plan.pricing.rates.map((rate) => rate.interval)))];
      setPlanListAndBillingCycle(uniqueIntervals?.length > 0 ? uniqueIntervals[0] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans?.length]);

  useEffect(() => {
    if (planList?.length > 0) {
      const existingPlan = planList?.find((plan) => plan?._id === formData?.plan?._id);
      onHandleChange("plan", existingPlan || planList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planList]);

  return (
    <div className="space-y-6">
      <H3 className="!font-semibold">Let's get your first instance set up.</H3>
      <DraggableContainer>
        {planList?.map((item, index) => (
          <PlanItem
            key={index}
            data={item}
            formData={formData}
            onClick={() => onHandleChange("plan", item)}
          />
        ))}
      </DraggableContainer>
      {planList?.length > 0 && (
        <div className="relative flex items-center justify-between rounded-lg border border-gray-200 bg-white px-4 py-3">
          <div className="flex flex-shrink items-center gap-2">
            <Toggle
              checked={formData?.interval === "year"}
              onChange={(e) => setPlanListAndBillingCycle(formData?.interval === "month" ? "year" : "month")}
            />
            <div>{`Switch to ${formData?.interval === "month" ? "yearly" : "monthly"} billing`}</div>
          </div>
          {formData?.plan?.pricing?.rates?.find((rate) => rate?.interval === "year")?.discount_amount > 0 && (
            <div className="flex-shrink-0 rounded-md border border-highlightColor px-2 py-1 text-sm font-semibold text-highlightColor">Save ${formData?.plan?.pricing?.rates?.find((rate) => rate?.interval === "year")?.discount_percentage}%</div>
          )}
        </div>
      )}
      <div className="relative space-y-2 border-t border-gray-200 pt-4">
        <div className="flex items-center justify-between text-base text-gray-500">
          <div className="">{formData?.plan?.free_trial?.status && formData?.plan?.free_trial?.days > 0 ? `Total after ${formData?.plan?.free_trial?.days}-day free trial` : `Total`}</div>
          <div className="">{`${formatPrice(formData?.plan?.pricing?.rates?.find((rate) => rate?.interval === formData?.interval)?.amount - formData?.plan?.pricing?.rates?.find((rate) => rate?.interval === formData?.interval)?.discount_amount || 0)}/${formData?.interval}`}</div>
        </div>
        <div className="flex items-center justify-between text-xl font-semibold">
          <div className="">Total due today</div>
          <div className="">{`${formatPrice(formData?.plan?.pricing?.rates?.find((rate) => rate?.interval === formData?.interval)?.amount - formData?.plan?.pricing?.rates?.find((rate) => rate?.interval === formData?.interval)?.discount_amount || 0)}`}</div>
        </div>
      </div>
      <div className="relative space-y-4">
        <Button
          version="primary"
          className="!w-full"
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={onHandleSubmit}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default ChoosePlan;
