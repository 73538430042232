import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { apiUrl } from "src/config/host";
import { urlBuilder } from "src/helpers/urlBuilder";

const ongoingRequests = new Map(); // Map to track ongoing requests by dynamic URL

const useFetch = (url, options = {}, token = "", canCall = true) => {
  let headers = { "Content-Type": "application/json", Accept: "application/json" };

  const [response, setResponse] = useState({ data: [], meta: {} });
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState({ idle: true, fetching: false, done: false, fail: false });

  let params = options.params;
  delete options.params;

  const optionsRef = useRef({
    headers,
    ...options,
  });

  const requestData = () => {
    setStatus({ idle: false, fetching: true, done: false, fail: false });
    (async () => {
      try {
        // Construct the dynamic URL
        const dynamicUrl = urlBuilder(url, params);

        // Check if there's an ongoing request for the same dynamic URL
        if (ongoingRequests.has(dynamicUrl)) {
          // Abort the ongoing request associated with the dynamic URL
          ongoingRequests.get(dynamicUrl).abort();
        }

        const controller = new AbortController();
        ongoingRequests.set(dynamicUrl, controller);

        const signal = options.signal || controller.signal;

        let response = await axios(apiUrl + urlBuilder(url, params), { ...optionsRef.current, data: options.data, params: options.query, method: optionsRef.current.method || "get", signal });

        if (response.status === 401 && response.data?.invalid) {
          localStorage.removeItem("userId");
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");

          window.location.href = "/login";
          return;
        }

        ongoingRequests.delete(dynamicUrl); // Remove the dynamic URL from ongoing requests
        setResponse(response.data);
        setMessage(response.message);
        setStatus({ idle: false, fetching: false, done: true, fail: false });
      } catch (e) {
        if (e.message !== "canceled") {
          setStatus({ idle: false, fetching: false, done: false, fail: true });
        }
        setMessage(e?.response?.data?.message);
      }
    })();
  };

  useEffect(() => {
    if (url && canCall) {
      requestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, canCall]);

  useEffect(() => {
    if (token && canCall) {
      optionsRef.current.headers.Authorization = `Bearer ${token}`;
      requestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, canCall]);

  const refreshData = () => {
    if (url) {
      requestData();
    }
  };

  return { response, message, status, refreshData };
};

export default useFetch;
