import React from "react";
import SeoTitle from "src/components/Shared/Text/SeoTitle";

const AuthLayout = ({ children, ...props }) => {
  return (
    <>
      <SeoTitle title={props?.site.name} />
      <div className="flex min-h-full w-full flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">{children}</div>
    </>
  );
};

export default AuthLayout;
