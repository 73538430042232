import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Image from "src/components/Shared/Image";

const Logo = ({ children, ...props }) => {
  const [visibleLogo, setVisibleLogo] = useState(null);

  useEffect(() => {
    let logoExists = props.site?.image_logo ? props.site?.image_logo + "?" + Date.now() : null;
    if (logoExists) setVisibleLogo(logoExists);
  }, [props.site]);

  return (
    <div className="flex h-[56px] flex-shrink-0 items-center">
      {children}
      <div className="sm:h-13 h-12 sm:py-[3px]">
        <Image
          image={visibleLogo}
          alt={"logo"}
          classes="object-contain object-left"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, null)(Logo);
