import { Navigate, Route, Routes } from "react-router-dom";
import FreeAuthRoute from "src/routes/FreeAuthRoute";
import RouteNotFound404 from "src/pages/route-not-found-404";
import SelfSetup from "src/pages/self-setup";
import Success from "src/pages/success";
import Cancel from "src/pages/cancel";

//Router
export default (
  <Routes>
    <Route
      path="/"
      element={
        <FreeAuthRoute>
          <SelfSetup />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/success"
      element={
        <FreeAuthRoute>
          <Success />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/cancel"
      element={
        <FreeAuthRoute>
          <Cancel />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/404"
      element={<RouteNotFound404 />}
    />
    <Route
      path="*"
      element={
        <Navigate
          replace
          to="/404"
        />
      }
    />
  </Routes>
);
