import React from "react";
import { Helmet } from "react-helmet";

const SeoTitle = ({ title }) => {
  return (
    <Helmet
    // style={{
    //   "cssText": `
    //       :root {
    //         -color1: '#000000'
    //       }
    //   `
    // }}
    >
      <title>{title || "Portal"}</title>
    </Helmet>
  );
};

export default SeoTitle;
