import { GET_SITE_SETTINGS_DATA } from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";

export const getOpenSiteSettings =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest("GET", `/site-settings/details`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
