import React from "react";
import { connect } from "react-redux";
import AuthLayout from "src/layouts/AuthLayout";

const FreeAuthRoute = ({ site, children }) => {
  return <AuthLayout site={site}>{children}</AuthLayout>;
};

const mapStateToProps = (state) => {
  return {
    site: state?.site,
  };
};

export default connect(mapStateToProps, null)(FreeAuthRoute);
