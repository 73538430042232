import React from "react";

const WizardLayout = ({ leftHeaderContent = null, rightHeaderContent = null, children }) => {
  return (
    <>
      <div className="-mx-2 -my-12 flex flex-shrink flex-grow flex-col sm:-mx-6 lg:-mx-8">
        <header className="sticky top-0 z-10 flex h-20 flex-shrink-0 items-center border-b border-gray-200 bg-white">
          <div className="flex h-full w-full items-center justify-between px-6">
            <div className="flex h-full items-center">{leftHeaderContent && leftHeaderContent}</div>
            {/* {rightHeaderContent && <div className="space-x-2">{rightHeaderContent}</div>} */}
          </div>
        </header>
        <div className="flex-shrink flex-grow bg-gray-50 pb-10 pt-20">
          <div className="mx-auto max-w-2xl rounded-lg bg-white px-6 pb-8 pt-2 shadow">{children}</div>
        </div>
      </div>
    </>
  );
};

export default WizardLayout;
