import React, { useState } from "react";
import { H3 } from "../Shared/Text/Headers";
import Input from "../Shared/Forms/Inputs/Input";
import Button from "../Shared/Buttons/Button";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";

const BasicForm = ({ formData = null, onHandleChange = () => {}, setView = () => {} }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await apiRequest("POST", `/basic-data/validation`, { body: { ...formData, plan: formData?.plan?._id } });
      setView("choosePlan");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="my-4 space-y-5">
      <H3
        caption="We'll only use this information to improve your metabase experience"
        className="!font-semibold">
        Tell us a bit about yourself
      </H3>
      <div className="space-y-6">
        <div className="relative">
          <Input
            inline={true}
            label={"Name"}
            wrapperClass={"bg-white"}
            placeholder={"John Doe"}
            value={formData?.name}
            onChange={(e) => onHandleChange("name", e.target.value)}
          />
        </div>
        <div className="relative">
          <Input
            inline={true}
            label={"Work email"}
            wrapperClass={"bg-white"}
            placeholder={"youlooknicetoday@company.com"}
            value={formData?.email}
            onChange={(e) => onHandleChange("email", e.target.value)}
          />
        </div>
        <div className="relative">
          <Input
            inline={true}
            type="password"
            name={"password"}
            label={"Password"}
            wrapperClass={"bg-white"}
            placeholder={"JohnDoe@321"}
            value={formData?.password}
            onChange={(e) => onHandleChange("password", e.target.value)}
          />
        </div>
        <div className="relative">
          <Input
            inline={true}
            label={"Company"}
            wrapperClass={"bg-white"}
            placeholder={"Google Inc."}
            value={formData?.company_name}
            onChange={(e) => onHandleChange("company_name", e.target.value)}
          />
        </div>
        <div className="relative space-y-4 pt-5">
          <Button
            version="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
            className="!w-full"
            onClick={onHandleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BasicForm;
